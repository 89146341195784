.skills {
  overflow: visible;
  padding: 100px 0 108px;
  margin: 0 auto;
}

.skills__section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.skills__list {
  width: 750px;
  padding: 0;
  list-style-type: none;
  margin: 0 80px 0 0;
  display: flex;
  flex-wrap: wrap;
}

.skills__list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 20px 25px;
  max-width: 50%;
  margin: 0 23px 23px 0;
  background-color: #ffffff;
  color: black;
}

.skills__list-item_theme_light {
  box-shadow: 0px 0px 29px rgba(68, 88, 144, 0.12);
  border-radius: 10px;
}

.skills__list-item:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.skills__list-item_theme_light:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.skills__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.skills__icon {
  width: 30px;
  height: 30px;
}

.skills__text {
  align-self: flex-start;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: auto 15px;
}

.skills__percentage {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: right;
  color: black;
  padding: 1px 8px;
  background: #0dd7f7;
  border-radius: 30px;
  margin: 0;
}

.skills__text-container {
  max-width: 300px;
}

.skills__subheading {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.hvr-icon-grow-rotate {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-icon-grow-rotate .skills__icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-icon-grow-rotate:hover .skills__icon,
.hvr-icon-grow-rotate:focus .skills__icon,
.hvr-icon-grow-rotate:active .skills__icon {
  -webkit-transform: scale(1.2) rotate(0deg);
  transform: scale(1.2) rotate(0deg);
}

@media screen and (max-width: 1200px) {
  .skills__section-container {
    max-width: 760px;
    align-items: flex-start;
    flex-direction: column-reverse;
    margin: 0 auto;
  }

  .skills__text-container {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 800px) {
  .skills {
    padding: 0;
  }

  .skills__section-container {
    max-width: 100%;
    padding: 50px 5%;
  }

  .skills__list {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .skills__list-item {
    margin: 0 0 25px 0;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .skills__list-item {
    padding: 10px 15px;
    max-width: 95%;
  }
}
