.next {
  padding: 31px 0 49px;
  background-image: url("../../images/backgrounds/header_bg.png");
  background-position: top;
  background-size: 80%;
  background-repeat: no-repeat;
}

.next__text-container {
  max-width: 330px;
  margin: 0 auto;
  text-align: center;
}

.next__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  /* color: #767e90; */
}

.next__list {
  max-width: 1100px;
  margin: 113px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style-type: none;
  height: 540px;
}

.next__list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  width: 347px;
  height: 400px;
  margin: 0 35px 0 0;
  padding: 35px 59px 59px;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
    url("../../images/backgrounds/next_bg.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  transition: 0.2s;
}

.next__list-item_theme_light {
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.7)),
    url("../../images/backgrounds/next_bg.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: center;
}

.next__list-item:nth-of-type(1) {
  background-position: left;
}

.next__list-item:nth-of-type(3) {
  background-position: right;
}

.next__list-item:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.next__list-item_theme_light:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.next__list-item:last-of-type {
  margin: 0;
}

.next__number {
  width: 40px;
  padding: 15px 10px;
  margin: 0;
  background: white;
  color: black;
  border-radius: 50px 15px 50px 50px;
  font-weight: bold;
  font-size: 31px;
  line-height: 34px;
  text-align: center;
  transition: 0.2s;
}

.next__list-item:hover .next__number {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.next__action-word {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.next__explanation {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.next__link {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #0dd7f7;
  text-decoration: none;
  transition: 0.2s;
}

.next__link:hover {
  text-decoration: underline;
}

.next__button {
  position: relative;
  background-color: unset;
  border: none;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: #0dd7f7;
  transition: 0.2s;
  cursor: pointer;
}

.next__button:hover {
  text-decoration: underline;
}

.next__button:hover .next__tooltip {
  opacity: 1;
  text-decoration: none;
}

.next__tooltip {
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  position: absolute;
  color: white;
  background-color: white;
  top: calc(100% + 5px);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  background-color: #181818;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
}

.next__tooltip_theme_light {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: white;
  color: black;
}

.next__tooltip-text {
  margin: 0;
  font-weight: normal;
  font-size: 12px;
}

.next__quiestion {
  margin: 0;
  padding: 5px 12px;
  font-size: 20px;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #0dd7f7;
}

@media screen and (max-width: 1200px) {
  .next__list {
    max-width: 90%;
    margin: 50px auto 0;
    height: 100%;
  }

  .next__list-item {
    max-width: 23%;
    height: 400px;
    margin: 0;
    padding: 4%;
  }
}

@media screen and (max-width: 800px) {
  .next__list {
    flex-direction: column;
  }

  .next__list-item {
    max-width: 100%;
    width: 80%;
    height: 300px;
    margin-bottom: 25px;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.7)),
      url("../../images/backgrounds/next_bg_alt.jpg");
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .next__list-item_theme_light {
    background: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.7)
      ),
      url("../../images/backgrounds/next_bg_alt.jpg");
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }

  .next__list-item:last-of-type {
    margin: 0;
    background-position: bottom;
  }

  .next__list-item:nth-of-type(1) {
    background-position: top;
  }
}

@media screen and (max-width: 550px) {
  .next {
    padding: 50px 0;
    background-size: cover;
  }

  .next__text-container {
    max-width: 60%;
    margin: 0 auto;
  }

  .next__heading {
    font-size: 25px;
    line-height: 40px;
  }

  .next__list {
    flex-direction: column;
    max-width: 100%;
    margin: 50px auto 0;
    height: 100%;
  }

  .next__list-item {
    width: 80%;
    height: 100%;
    margin: 0 0 25px 0;
    padding: 15px;
  }

  .next__list-item:last-of-type {
    margin: 0;
  }

  .next__number {
    width: 30px;
    padding: 10px 5px;
    align-self: flex-end;
    font-size: 22px;
    line-height: 25px;
  }
}
