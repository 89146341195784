.popup {
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear;
  z-index: 10;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__image {
  max-width: 75vw;
  max-height: 75vh;
  background-color: unset;
  border-radius: 20px;
}

.popup__loading-image {
  padding: 100px;
}

.popup__close {
  background-image: url("../../images/icons/close_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
  background-size: 90%;
  border: none;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
}

.popup__close:active {
  outline: none;
}

.popup__close:focus {
  outline: none;
}

.popup__close:hover {
  cursor: pointer;
  opacity: 0.6;
}

.popup__img-container {
  display: flex;
  position: fixed;
  background-color: white;
  margin: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 3;
  color: black;
  border-radius: 20px;
  background-image: url("../../images/backgrounds/pic_bg.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: 100%;
  animation: animation 50s linear infinite;
  animation-direction: normal, reverse;
  overflow: hidden;
}

.popup__img-subline {
  color: white;
  font-weight: 400;
  font-size: 17px;
  position: absolute;
  bottom: 30px;
  right: 40px;
  margin: 0;
  z-index: 2;
}

.popup__color-box {
  position: absolute;
  width: 110%;
  height: 80px;
  bottom: -1px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
  z-index: 1;
}

@keyframes animation {
  0% {
    background-position: top;
  }

  100% {
    background-position: bottom;
  }
}

@media screen and (max-width: 550px) {
  .popup__close {
    top: 15px;
    right: 15px;
  }

  .popup__img-container {
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 50%;
    background-color: unset;
    /* background-image: url("../../images/backgrounds/popup_phone_bg.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    animation: none;
    margin: 0 auto;
  }

  .popup__image {
    object-fit: contain;
    transform: scale(2.3);
  }

  .popup__image:hover {
    transform: scale(2.3);
  }

  .popup__img-subline {
    text-align: center;
    bottom: 15px;
    right: 0;
    left: 0;
  }

  .popup__color-box {
    height: 50px;
  }
}
