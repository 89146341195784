.experience {
  padding: 100px 0;
  background-color: unset;
}

.experience__section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
}

.experience__text-container {
  max-width: 300px;
}

.experience__text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.experience__link {
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  padding: 12px 32px;
  border-radius: 50px;
  background-color: #0c3d71;
  color: white;
  border: none;
  margin-top: 30px;
  transition: 0.2s;
}

.experience__link:hover {
  cursor: pointer;
  opacity: 0.7;
}

.experience__list {
  width: 750px;
  margin: 0 0 0 80px;
  padding: 0;
  list-style-type: none;
}

.experience__list-item {
  display: grid;
  grid-template-columns: 40% 55%;
  column-gap: 5%;
  border-bottom: 1px solid rgba(173, 181, 189, 0.16);
  margin: 35px 0 0 0;
}

.experience__list-item:first-of-type {
  margin: 0;
}

.experience__icon {
  width: 20px;
  margin: 0 30px 0 0;
}

.experience__list-box {
  display: flex;
  flex-wrap: wrap;
  max-width: 200px;
}

.experience__list-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 0;
}

.experience__date {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  max-width: 75%;
}

.experience__name {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  margin: 14px 0 46px 50px;
}

.experience__description {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.experience__additional {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.experience__add-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: unset;
  border: none;
  color: unset;
  padding: 0;
  margin-bottom: 20px;
  transition: 0.5s ease-in-out;
  width: 150px;
  align-self: flex-end;
}

.experience__add-button:hover {
  opacity: 0.7;
  cursor: pointer;
}

.experience__add-icon {
  width: 35px;
  height: 25px;
  background-image: url("../../images/icons/down_arrow_blue.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  background-color: unset;
  transition: 0.5s ease-in-out;
}

.experience__add-icon_alt {
  transform: rotate(180deg);
}

.experience__button-caption {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0 15px 0 0;
  transition: 0.5s ease-in-out;
}

.experience__height-control_hidden {
  margin-bottom: 46px;
  transition: 0.5s ease-in-out;
}

.experience__height-control {
  margin-bottom: 100%;
}

.experience__add-list {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 46px;
  transition: 0.1s ease-in-out;
  height: 100%;
  list-style-type: none;
  padding: 0;
}

.experience__add-list_hidden {
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}

.experience__add-list-item {
  margin-bottom: 10px;
}

.experience__add-list-item:last-of-type {
  margin-bottom: 0;
}

.experience__add-list-item::before {
  content: "•";
  color: #0dd7f7;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

@media screen and (max-width: 1200px) {
  .experience__section-container {
    max-width: 750px;
    flex-direction: column;
  }

  .experience__text-container {
    margin-bottom: 100px;
  }

  .experience__list {
    max-width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .experience {
    padding: 50px 0;
  }

  .experience__section-container {
    max-width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .experience__icon {
    margin: 0 10px 0 0;
  }

  .experience__date {
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
  }

  .experience__name {
    font-size: 15px;
    line-height: 17px;
    margin: 14px 0 26px 30px;
  }

  .experience__description {
    font-size: 15px;
    line-height: 16px;
    margin: 0 0 25px 0;
  }

  .experience__additional {
    grid-column-start: 1;
    grid-column-end: 4;
    max-width: 90%;
    margin: 0 auto;
  }
}
