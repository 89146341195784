body {
  background-color: unset;
  margin: 0;
  padding: 0;
}

.page {
  font-family: "Inter", "Arial", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
  background-position: center;
  height: 100%;
  width: 100%;
  color: white;
  margin: 0 auto;
  z-index: 1;
  background-color: #181818;
  overflow: hidden;
}

.page_theme_light {
  color: black;
  background-color: white;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #0dd7f7;
}

body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.content {
  margin: 0 auto;
  max-width: 1800px;
}

.section-container {
  margin: 0 auto;
  max-width: 1050px;
}

.hidden-component {
  visibility: hidden;
  opacity: 0;
  margin-left: -1000px;
}

.mounted-component {
  margin-left: 0;
  visibility: visible;
  opacity: 1;
  transition: margin 1s, visibility 0.5s, opacity 0.5s linear;
}

.hidden {
  display: none;
}

.blue-coloring {
  color: #0dd7f7;
}

.background-color-dark {
  background-color: #232323;
}

.background-color-light {
  background: #f7fbfe;
}

.section__heading {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  border-bottom: 3px solid #0dd7f7;
  display: inline-block;
  margin: 20px 0;
}

.section__name {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
}

.mailto {
  text-decoration: unset;
  color: unset;
}

.hvr-underline-reveal {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}
.hvr-underline-reveal:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0dd7f7;
  height: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-underline-reveal:hover:before,
.hvr-underline-reveal:focus:before,
.hvr-underline-reveal:active:before {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

@media screen and (max-width: 1200px) {
  .section-container {
    max-width: 760px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 550px) {
  .section-container {
    max-width: 100%;
    padding: 0 5%;
  }

  .section__name {
    font-size: 12px;
    line-height: 17px;
  }

  .section__heading {
    font-size: 25px;
    line-height: 35px;
  }
}
