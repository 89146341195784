.footer {
  display: flex;
  width: 100%;
  padding: 50px 0;
}

.footer__list {
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
}

.footer__list-item {
  display: flex;
  margin: 0 45px 0 0;
  width: 45px;
  height: 45px;
  background-color: #0dd7f7;
  border-radius: 50%;
}

.footer__list-item:hover {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.footer__list-item:last-of-type {
  margin: 0;
}

.footer__link {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  filter: invert(1);
}

.footer__link:hover {
  background-size: 60%;
  filter: invert(0);
}

.footer__link_type_instagram {
  background-image: url("../../images/icons/insta_icon.svg");
}

.footer__link_type_github {
  background-image: url("../../images/icons/github_icon.svg");
}

@media screen and (max-width: 550px) {
  .footer {
    padding: 0 0 80px;
  }
}
