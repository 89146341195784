.contact {
  padding: 120px 0 86px;
  background-image: url("../../images/backgrounds/contact_bg.png");
  background-color: unset;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact__box {
  margin: 0 auto;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact__text-container {
  width: 354px;
  margin-right: 216px;
}

.contact__list {
  padding: 0;
  list-style-type: none;
}

.contact__list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.contact__list-item:last-of-type {
  margin: 0;
}

.contact__icon {
  width: 42px;
  height: 42px;
  background: #0dd7f7;
  border-radius: 50px 20px 50px 50px;
  margin-right: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
}

.contact__icon_location {
  background-image: url("../../images/icons/contact/contact_location_icon.svg");
}

.contact__icon_mail {
  background-image: url("../../images/icons/contact/contact_mail_icon.svg");
}

.contact__icon_phone {
  background-image: url("../../images/icons/contact/contact_phone_icon.svg");
}

.contact__list-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0dd7f7;
}

.contact__data-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin: 6px 0 0 66px;
}

.contact__form-container {
  max-width: 513px;
}

.contact__form-tagline {
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 0 12px;
}

.contact__form-text {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #bfbfbf;
  margin: 0 0 12px;
}

.contact__form-text_theme_light {
  color: unset;
}

.contact__form-subtext {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 18px;
}

.contact__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}

.contact__form-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.contact__label {
  display: flex;
  flex-direction: column;
  width: 45%;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #767e90;
  position: relative;
}

.contact__textarea-label {
  position: relative;
  width: 100%;
  height: 155px;
  border: 1px solid #828282;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  color: #767e90;
  margin-bottom: 28px;
}

.contact__text-label {
  margin: 0 0 0 10px;
  padding: 0 10px;
  position: absolute;
  top: -12px;
  background-color: #181818;
}

.contact__text-label_theme_light {
  background-color: white;
}

.contact__input {
  background-color: unset;
  color: white;
  border: none;
  height: 30px;
  font-size: 20px;
  padding: 10px;
}

.contact__textarea {
  font-family: "Inter", "Arial", sans-serif;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  resize: none;
  background-color: unset;
  color: white;
  border: none;
  font-size: 20px;
  padding: 15px;
}

.contact__input_theme_light {
  color: black;
}

.contact__input-error {
  position: absolute;
  font-size: 12px;
  line-height: 12px;
  color: #ee3465;
  top: calc(100% + 5px);
  left: 0;
  z-index: 12;
}

.contact__submit {
  color: white;
  background: #0c3d71;
  box-sizing: border-box;
  border-radius: 50px;
  border: none;
  max-width: 165px;
  padding: 12px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  border: 1px solid white;
  transition: 0.2s;
  background-color: #181818;
}

.contact__submit_theme_light {
  background-color: white;
  border: 1px solid #181818;
  color: unset;
}

.contact__submit:hover {
  cursor: pointer;
  opacity: 0.7;
}

.contact__submit_disabled {
  opacity: 0.7;
  color: #828282;
  border: 1px solid #828282;
}

.contact__submit_disabled:hover {
  cursor: default;
}

.submit__box {
  border-radius: 15px;
  border: 1px solid #0dd7f7;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
}

.submit__text {
  margin: 50px 0;
  font-size: 25px;
  line-height: 27px;
  display: inline-block;
}

@media screen and (max-width: 1250px) {
  .contact__box {
    max-width: 90%;
  }

  .contact__text-container {
    margin-bottom: 50px;
  }

  .contact__text-container {
    width: 354px;
    margin: 0;
  }
}

@media screen and (max-width: 1100px) {
  .contact__box {
    margin: 0 auto;
    max-width: 90%;
  }

  .contact__data-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    margin: 15px 0 0;
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .contact {
    padding: 50px 0;
  }

  .contact__list {
    margin: 25px 0 50px;
  }

  .contact__box {
    flex-direction: column;
    align-items: center;
  }

  .contact__text-container {
    width: 90%;
    max-width: 100%;
    margin-right: 0;
  }

  .contact__form-subtext {
    margin-bottom: 70px;
  }

  .contact__input {
    font-size: 15px;
  }

  .contact__textarea {
    font-size: 15px;
  }

  .contact__form-container {
    max-width: 90%;
  }
}

@media screen and (max-width: 550px) {
  .contact__list {
    margin: 25px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(173, 181, 189, 0.5);
  }

  .contact__list-item {
    margin-bottom: 20px;
  }

  .contact__data-text {
    margin: 0 0 0 66px;
  }

  .contact__form-tagline {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 25px;
  }

  .contact__form-text {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 25px;
  }

  .contact__form-subtext {
    margin-bottom: 35px;
  }

  .contact__form-box {
    flex-direction: column;
    margin-bottom: 0;
  }

  .contact__label {
    width: 100%;
    margin-bottom: 24px;
  }

  .submit__box {
    margin: 0 auto;
    width: 100%;
  }

  .submit__text {
    max-width: 80%;
    margin: 50px auto 25px;
  }
}
