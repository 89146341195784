.mobile-bar {
  margin: 0;
  padding: 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 30;
  opacity: 0.8;
}

.mobile-bar__list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
  padding: 0 10px;
  margin: 0;
}

.mobile-bar__list-item {
  display: flex;
  width: 25%;
  height: 50px;
}

.mobile-bar__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: unset;
  color: unset;
}

.mobile-bar__button {
  padding: 0;
  background-color: unset;
  border: none;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  height: 100%;
}

.mobile-bar__button-icon {
  margin: auto;
  width: 20px;
}

@media screen and (min-width: 551px) {
  .mobile-bar {
    display: none;
  }
}
