.services {
  background-image: url("../../images/backgrounds/services_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 173px;
}

.services__text-container {
  max-width: 450px;
  text-align: center;
  margin: 125px 0 115px;
}

.services__paragraph {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.services__list {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1055px;
}

.services__list-item {
  background-image: url("../../images/backgrounds/services_item.png");
  background-position: center;
  max-width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 112px;
  padding: 25px;
  transition: 0.2s;
  border-radius: 15px;
  background-color: rgba(95, 95, 95, 0.1);
  /* background: linear-gradient(rgba(255, 255, 255, 0), rgb(0, 221, 255, 0.3)); */
}

.services__list-item:last-of-type {
  margin: 0;
}

.services__list-item:hover {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
}

.services__list-item_theme_light:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.services__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.services__icon {
  width: 25px;
  height: 25px;
}

.services__list-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #767e90;
}

.services__list-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: none;
  color: white;
  transition: 0.2s;
}

.services__list-link_theme_light {
  color: #0dd7f7;
}

.services__list-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1000px) {
  .services__list {
    max-width: 90%;
  }

  .services__list-item {
    /* max-width: 23%; */
    margin-right: 50px;
  }
}

@media screen and (max-width: 650px) {
  .services {
    padding: 20px 0 50px;
  }

  .services__text-container {
    max-width: 80%;
    margin: 75px 0 55px;
  }

  .services__paragraph {
    text-align: center;
    max-width: 100%;
    margin: 0;
  }

  .services__list {
    flex-direction: column;
  }

  .services__list-item {
    margin: 0 0 45px 0;
    width: 100%;
    max-width: 70%;
    border-bottom: 1px solid rgba(173, 181, 189, 0.5);
    padding-bottom: 25px;
    border-radius: 15px 15px 0 0;
    background-color: unset;
  }
}
