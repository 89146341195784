.portfolio {
  background-image: url("../../images/backgrounds/portfolio_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 150px 0;
}

.portfolio__section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.portfolio__text-container {
  max-width: 300px;
}

.portfolio__buttons {
  padding: 0;
  list-style-type: none;
  display: flex;
}

.portfolio__buttons_theme_light {
  color: #394153;
}

.portfolio__button-item {
  margin-right: 30px;
}

.portfolio__button-item:last-of-type {
  margin-right: 0;
}

.portfolio__button {
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  border: none;
  color: unset;
  background-color: unset;
  transition: 0.2s;
}

.portfolio__button:hover {
  cursor: pointer;
  color: #0dd7f7;
  opacity: 0.7;
}

.portfolio__button_active {
  transition: 0.2s;
  color: #0dd7f7;
  border-bottom: 1px solid #0dd7f7;
}

.portfolio__tagline {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.portfolio__gallery-box {
  width: 750px;
  display: flex;
  flex-direction: column;
}

.portfolio__button-container {
  width: 100%;
  margin-left: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio__gallery-button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  border: none;
  margin-right: 10px;
  padding: 0;
  transition: 0.2s;
  cursor: pointer;
}

.portfolio__gallery-button_theme_light {
  background-color: gray;
}

.portfolio__gallery-button:last-of-type {
  margin: 0;
}

.portfolio__gallery-button_active {
  background-color: #0dd7f7;
}

.portfolio__gallery-button:hover {
  opacity: 0.7;
}

.portfolio__gallery-container {
  width: 100%;
  margin-left: 80px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.portfolio__gallery-item {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 263px;
  background-color: unset;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.2s;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  background-image: url("../../images/backgrounds/pic_bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.portfolio__gallery-item_type_site {
  background-image: url("../../images/backgrounds/site_bg.jpg");
}

.portfolio__gallery-item:hover {
  cursor: pointer;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
  animation: list-animation 30s linear infinite;
  animation-direction: normal, reverse;
  overflow: hidden;
}

.portfolio__gallery-item:nth-of-type(1) {
  width: 65%;
  margin: 0 5% 5% 0;
}

.portfolio__gallery-item:nth-of-type(2) {
  width: 30%;
}

.portfolio__gallery-item:nth-of-type(3) {
  width: 30%;
}

.portfolio__gallery-item:nth-of-type(4) {
  width: 65%;
  margin: 0 0 5% 5%;
}

.portfolio__gallery-item:nth-of-type(5) {
  width: 47.5%;
  margin-right: 5%;
}

.portfolio__gallery-item:nth-of-type(6) {
  width: 47.5%;
}

.portfolio__gallery-item_type_site:hover .porfolio__image {
  transform: scale(1.1);
}

.portfolio__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: 0.2s;
}

.portfolio__image:hover {
  transform: scale(1.1);
}

.portfolio__loading {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  height: 100px;
  background-color: unset;
  overflow: hidden;
  transition: 0.2s;
}

.protfolio__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-decoration: none;
  color: unset;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.protfolio__link:hover .portfolio__link-icon {
  transform: scale(1.2);
}

.portfolio__link-icon {
  justify-self: center;
  align-self: flex-end;
  z-index: 11;
  width: 20px;
  height: 20px;
  margin: 15px 15px 0 0;
  transition: 0.2s;
}

.portfolio__container {
  transition: 0.2s;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1));
  padding: 15px;
  margin-bottom: -30px;
}

.protfolio__subline {
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 15px 0;
}

.portfolio__list {
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.protfolio__link:hover .portfolio__list {
  visibility: visible;
  opacity: 1;
}

.protfolio__link:hover .portfolio__container {
  margin-bottom: 0;
}

.protfolio__list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin: 0 15px 0 0;
}

.portfolio__icon {
  width: 70%;
  height: auto;
  object-fit: cover;
  transition: 0.2s;
}

.protfolio__list-item:hover .portfolio__icon {
  transform: scale(1.5);
}

@keyframes list-animation {
  0% {
    background-position: center;
  }

  100% {
    background-position: bottom;
  }
}

@media screen and (max-width: 1200px) {
  .portfolio__section-container {
    max-width: 760px;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
  }

  .portfolio__text-container {
    max-width: 100%;
    margin: 0 0 50px;
  }

  .portfolio__gallery-box {
    max-width: 100%;
    margin: 0;
  }

  .portfolio__button-container {
    margin: 0 0 20px;
    width: 100%;
  }

  .portfolio__gallery-container {
    margin: 0;
  }
}

@media screen and (max-width: 800px) {
  .portfolio {
    padding: 50px 0;
  }

  .portfolio__section-container {
    max-width: 90%;
  }

  .portfolio__gallery-container {
    max-width: 100%;
    margin: 0;
  }

  .portfolio__gallery-item {
    color: white;
    position: relative;
    height: 263px;
    margin: 0 0 25px;
  }
}

@media screen and (max-width: 550px) {
  .portfolio__gallery-box {
    position: relative;
    margin: 0 auto;
  }

  .portfolio__gallery-container {
    position: relative;
    margin: 0 auto;
  }

  .portfolio__gallery-item {
    color: white;
    position: relative;
    height: 263px;
    margin: 0 0 25px;
  }

  .portfolio__gallery-item:nth-of-type(1) {
    width: 100%;
    margin: 0 0 25px 0;
  }

  .portfolio__gallery-item:nth-of-type(2) {
    width: 100%;
  }

  .portfolio__gallery-item:nth-of-type(3) {
    width: 100%;
  }

  .portfolio__gallery-item:nth-of-type(4) {
    width: 100%;
    margin: 0 0 25px 0;
  }

  .portfolio__gallery-item:nth-of-type(5) {
    width: 100%;
    margin: 0 0 25px 0;
  }

  .portfolio__gallery-item:nth-of-type(6) {
    width: 100%;
    margin: 0;
  }

  .portfolio__list {
    visibility: visible;
    opacity: 1;
  }

  .portfolio__container {
    margin-bottom: 0;
  }
}
