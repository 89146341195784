.header {
  position: relative;
  background-image: url("../../images/backgrounds/header_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 50px 100px 100px;
}

.header__bg-container {
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  width: 400px;
  height: 100%;
  z-index: 0;
}

.header__bg-container_left {
  background-image: url("../../images/backgrounds/header_left_bg.png");
  left: -10px;
  bottom: -600px;
}

.header__bg-container_right {
  background-image: url("../../images/backgrounds/header_right_bg.png");
  right: -10px;
  top: 0;
  background-position: right top;
}

.header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 5;
}

.header__container_bg {
  color: red;
}

.header__menu {
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.header__menu-item {
  margin-right: 45px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.header__main-logo {
  width: 100px;
}

.header__menu-item:last-of-type {
  margin: 0;
}

.header__icon {
  margin-right: 10px;
}

.header__button-container {
  display: flex;
  align-self: center;
}

.header__lang-switch {
  padding: 0;
  margin: 0 25px 0 0;
  border: unset;
  background-color: unset;
  color: unset;
  font-size: 18px;
  transition: 0.2s;
}

.header__lang-switch:hover {
  cursor: pointer;
}

.header__button {
  background-color: #0dd7f7;
  width: 49px;
  height: 49px;
  border-radius: 50%;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.header__button_theme_light {
  background-color: #394153;
}

.header__button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.header__button-icon {
  height: 20px;
  width: 20px;
}

.header__button:hover .header__button-icon {
  opacity: 1;
}

.header__content-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header__text-container {
  position: relative;
  padding-top: 100px;
  max-width: 500px;
  margin-left: 200px;
  z-index: 15;
}

.header__main-image {
  position: absolute;
  left: 60%;
  width: 350px;
  height: auto;
  opacity: 0.5;
  transition: 0.2s;
  z-index: 14;
}

.header__main-image:hover {
  transform: scale(1.2);
  opacity: 0.9;
}

.header__paragraph {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 23px;
  border-bottom: 3px solid #0dd7f7;
  display: inline-block;
}

.header__name-heading {
  font-size: 72px;
  line-height: 101px;
  letter-spacing: -3px;
  margin: 33px 0 16px 0;
}

.header__about-me {
  margin: 0 0 24px;
  font-size: 16px;
  line-height: 24px;
}

.header__about-me:last-of-type {
  margin: 0;
}

.header__links {
  margin-top: 60px;
  display: flex;
  padding: 0;
  list-style-type: none;
}

.header__link-item {
  margin-right: 23px;
}

.header__link {
  color: white;
  text-decoration: none;
  padding: 12px 32px;
  background: #0c3d71;
  border-radius: 50px;
  transition: 0.2s;
}

.header__link:hover {
  opacity: 0.7;
}

.header__logo-container {
  position: relative;
  width: 70%;
  margin: 250px auto 0;
  z-index: 5;
}

.header__logo-text {
  font-size: 12px;
  line-height: 31px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #bfbfbf;
  margin-bottom: 60px;
}

.header__logo-list-item {
  border-radius: 15px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__logo-list {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo-item {
  object-fit: contain;
  width: 125px;
}

.header__logo-item_middle {
  width: 175px;
}

.header__check {
  display: none;
}

.header__check + .header__check-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  background-color: white;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.header__check + .header__check-label::before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  background-color: #0dd7f7;
  background-image: url("../../images/icons/dark_theme_icon.svg");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 1px;
  right: 3px;
  transition: all 0.3s ease-out;
}

.header__check + .header__check-label::after {
  color: white;
  position: absolute;
  top: 3px;
  left: 1px;
  font-size: 20px;
  font-weight: 300;
  transition: all 0.3s ease-out;
}

.header__check:checked + .header__check-label {
  background-color: white;
}

.header__check:checked + .header__check-label::before {
  background-image: url("../../images/icons/light_theme_icon.svg");
  right: 26px;
}

.header__check:checked + .header__check-label::after {
  left: 1px;
}

.hvr-grow-rotate {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow-rotate:hover,
.hvr-grow-rotate:focus,
.hvr-grow-rotate:active {
  -webkit-transform: scale(1.1) rotate(4deg);
  transform: scale(1.1) rotate(4deg);
}

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #0dd7f7;
  height: 3px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

@media screen and (max-width: 1000px) {
  .header__menu {
    flex-direction: column;
    align-items: flex-start;
  }

  .header__menu-item {
    margin: 0 0 25px;
  }

  .header__button-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .header__lang-switch {
    margin: 0;
  }

  .header__button {
    margin-bottom: 25px;
  }

  .header__check + .header__check-label {
    margin-bottom: 20px;
  }

  .header__text-container {
    margin: 0 auto;
  }

  .header__logo-container {
    margin: 150px auto 0;
  }

  .header__logo-list-item {
    width: 100px;
    height: 100px;
  }

  .header__logo-item {
    width: 75px;
  }

  .header__logo-item_middle {
    width: 110px;
  }

  .header__main-image {
    width: 250px;
  }
}

@media screen and (max-width: 700px) {
  .header__name-heading {
    font-size: 42px;
    line-height: 45px;
    font-weight: 500;
    margin: 40px 0;
  }

  .header__text-container {
    padding-top: 50px;
  }

  .header__links {
    flex-direction: column;
  }

  .header__link-item {
    margin-bottom: 35px;
  }

  .header__logo-container {
    margin-top: 75px;
  }

  .header__main-image {
    left: 70%;
  }
}

@media screen and (max-width: 550px) {
  .header {
    padding: 25px 50px 50px;
  }

  .header__menu {
    align-items: center;
  }

  .header__container {
    flex-direction: column;
    align-items: center;
  }

  .header__button-container {
    display: none;
  }

  .header__lang-switch {
    margin-right: 25px;
  }

  .header__button {
    margin-bottom: 0;
  }

  .header__logo-container {
    width: 90%;
  }

  .header__logo-text {
    margin-bottom: 25px;
  }

  .header__logo-list-item {
    width: 100px;
    height: 100px;
  }

  .header__logo-item {
    width: 50px;
  }

  .header__logo-item_middle {
    width: 75px;
  }
}
